import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          staticStyle: { "align-items": "center" },
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(VRow, [
            _c(
              "p",
              {
                staticClass: "mx-auto",
                staticStyle: { "font-size": "1.5rem", color: "var(--dark)" },
              },
              [_c("b", [_vm._v(_vm._s(_vm.$t("patrimonial_infos")))])]
            ),
          ]),
          _c(
            VRow,
            [
              _c(VCol, { staticClass: "py-1" }, [
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.$t("total_assets")) + " "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            VRow,
            {
              staticClass: "totalAssetsRow d-inline-flex justify-center",
              class: _vm.isMobile() ? "mx-0" : "mx-10",
              staticStyle: { width: "100%", "max-width": "300px" },
              attrs: { justify: "center" },
            },
            [
              _c(
                VCol,
                { staticClass: "py-0 pr-0", attrs: { cols: "4" } },
                [
                  _c(VSelect, {
                    staticClass: "portfolio-size-currency-input",
                    attrs: {
                      rules: [_vm.required],
                      items: _vm.currencyOptions,
                      "item-text": "text",
                      "item-value": "value",
                      outlined: "",
                      dense: "",
                      color: "primary",
                      "data-test":
                        "Alpha:PatrimonialInfo:SelectCurrencyPreference",
                    },
                    model: {
                      value: _vm.User.CurrencyPreference,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "CurrencyPreference", $$v)
                      },
                      expression: "User.CurrencyPreference",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "py-0", attrs: { cols: "8" } },
                [
                  _c("money", {
                    staticClass: "pl-2",
                    class: [
                      _vm.User.TotalAssets > 0 ? "field" : "fieldRequired",
                    ],
                    attrs: {
                      prefix: _vm.getCurrencyPrefix(
                        _vm.User.CurrencyPreference
                      ),
                      thousands: ".",
                      decimal: ",",
                      min: "1",
                      rules: [
                        function (v) {
                          return (!!v && v != 0) || _vm.required()
                        },
                      ],
                      "data-test": "complete_profile:total_assets",
                    },
                    model: {
                      value: _vm.User.TotalAssets,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "TotalAssets", $$v)
                      },
                      expression: "User.TotalAssets",
                    },
                  }),
                  this.User.TotalAssets == 0
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "13px",
                            display: "flex",
                            color: "red",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("required")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            [
              _c(VCol, { attrs: { cols: "12" } }, [
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.$t("assets_origins")) + " "),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "d-inline-flex justify-center" }, [
            _c(
              "div",
              { staticClass: "mt-4 mx-12" },
              _vm._l(_vm.assetOptions, function (asset, i) {
                return _c(
                  VRow,
                  { key: i, attrs: { align: "center" } },
                  [
                    _c(VCheckbox, {
                      staticClass: "my-0 py-0",
                      attrs: {
                        color: "primary",
                        value: asset.id,
                        rules: [
                          function (v) {
                            return v.length > 0 || _vm.required()
                          },
                        ],
                        "data-test": "complete_profile:asset_origins",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { color: "var(--dark)" } },
                                  [_vm._v(_vm._s(_vm.$t(asset.key)))]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.markedOptions,
                        callback: function ($$v) {
                          _vm.markedOptions = $$v
                        },
                        expression: "markedOptions",
                      },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ]),
          _c(
            VRow,
            {
              staticClass: "patrimonial-info-buttons",
              attrs: { justify: "center" },
            },
            [
              _c(
                VBtn,
                {
                  staticClass: "btn_back mr-1",
                  attrs: {
                    outlined: "",
                    color: "primary",
                    loading: _vm.loading,
                    "data-test": "complete_profile:btn_back_patrimonial_info",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("go_back"))),
                  ]),
                ]
              ),
              _c(
                VBtn,
                {
                  staticClass: "btn_next ml-1",
                  attrs: {
                    type: "submit",
                    color: "primary",
                    loading: _vm.loading,
                    "data-test": "complete_profile:btn_next_patrimonial_info",
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("next"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            VRow,
            {
              class: _vm.isMobile() ? "mx-0" : "mx-10",
              staticStyle: { "text-align": "left" },
            },
            [
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "4", lg: "4", md: "4", sm: "4" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("company_name")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      rules: [_vm.required],
                    },
                    model: {
                      value: _vm.User.CompanyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "CompanyName", $$v)
                      },
                      expression: "User.CompanyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "3", lg: "3", md: "3", sm: "3" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("state_registration")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: { color: "primary", outlined: "", dense: "" },
                    model: {
                      value: _vm.User.StateRegistration,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "StateRegistration", $$v)
                      },
                      expression: "User.StateRegistration",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "5", lg: "5", md: "5", sm: "5" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("main_economic_activity")) + " "
                    ),
                  ]),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      rules: [_vm.required],
                    },
                    model: {
                      value: _vm.User.MainEconomicActivity,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "MainEconomicActivity", $$v)
                      },
                      expression: "User.MainEconomicActivity",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            {
              class: _vm.isMobile() ? "mx-0" : "mx-10",
              staticStyle: { "text-align": "left" },
            },
            [
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "6", lg: "6", md: "6", sm: "6" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("tax_address")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      hint: _vm.$t("tax_address_hint"),
                      rules: [_vm.required],
                    },
                    model: {
                      value: _vm.User.TaxAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "TaxAddress", $$v)
                      },
                      expression: "User.TaxAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "3", lg: "3", md: "3", sm: "3" },
                },
                [
                  _c(
                    "p",
                    { staticClass: "ml-2 mb-2" },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("cnae")) + " "),
                      _c(
                        VTooltip,
                        {
                          attrs: { right: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    VIcon,
                                    _vm._g(
                                      _vm._b({}, "v-icon", attrs, false),
                                      on
                                    ),
                                    [_vm._v("mdi-help-circle-outline")]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("extended_cnae")))])]
                      ),
                    ],
                    1
                  ),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: { color: "primary", outlined: "", dense: "" },
                    model: {
                      value: _vm.User.CNAE,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "CNAE", $$v)
                      },
                      expression: "User.CNAE",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                VRow,
                {
                  class: _vm.isMobile() ? "mx-0" : "mx-10",
                  staticStyle: { "text-align": "left" },
                },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "py-0 pr-0",
                      attrs: { cols: "7", xl: "3", lg: "3", md: "3", sm: "3" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("country")) + " "),
                      ]),
                      _c(VSelect, {
                        staticClass: "field",
                        attrs: {
                          color: "primary",
                          dense: "",
                          outlined: "",
                          items: _vm.countries,
                          "item-text": "name",
                          "return-object": "",
                          rules: [_vm.required],
                        },
                        on: { change: _vm.country_code_changed },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "auto",
                                  },
                                  attrs: { src: data.item.flag, alt: "" },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { "text-transform": "none" },
                                  },
                                  [_vm._v(_vm._s(data.item.name))]
                                ),
                              ]
                            },
                          },
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "auto",
                                  },
                                  attrs: { src: data.item.flag, alt: "" },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: { "text-transform": "none" },
                                  },
                                  [_vm._v(_vm._s(data.item.name))]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.countrySelected,
                          callback: function ($$v) {
                            _vm.countrySelected = $$v
                          },
                          expression: "countrySelected",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      staticStyle: { "text-align": "left" },
                      attrs: { xl: "9", lg: "9", md: "9", sm: "9" },
                    },
                    [
                      _c("h4", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("post_code")) + " "),
                        _c(
                          "span",
                          {
                            staticClass: "manually colorized pa-0 ml-3",
                            attrs: { color: "#ccc" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("inform_your_post_code")) +
                                " "
                            ),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.disabled_field = false
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("enter_manually")))]
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(VTextField, {
                            staticClass: "field mr-3",
                            staticStyle: { "max-width": "215px" },
                            attrs: {
                              color: "primary",
                              outlined: "",
                              dense: "",
                              rules: [_vm.required],
                            },
                            on: { keyup: _vm.post_code_changed },
                            model: {
                              value: _vm.User.PostCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.User, "PostCode", $$v)
                              },
                              expression: "User.PostCode",
                            },
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "manually",
                              attrs: {
                                href: "https://buscacepinter.correios.com.br/app/endereco/index.php",
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("dont_know_post_code")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            {
              class: _vm.isMobile() ? "mx-0" : "mx-10",
              staticStyle: { "text-align": "left" },
            },
            [
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "7", xl: "5", lg: "5", md: "5", sm: "5" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("city")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      loading: _vm.fields_loading,
                      rules: [_vm.required],
                      disabled: _vm.disabled_field,
                    },
                    model: {
                      value: _vm.User.City,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "City", $$v)
                      },
                      expression: "User.City",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "5", xl: "2", lg: "2", md: "2", sm: "2" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("state")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      disabled: _vm.disabled_field,
                      loading: _vm.fields_loading,
                      rules: [_vm.required],
                    },
                    model: {
                      value: _vm.User.State,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "State", $$v)
                      },
                      expression: "User.State",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "5", lg: "5", md: "5", sm: "5" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("neighborhood")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      disabled: _vm.disabled_field,
                      loading: _vm.fields_loading,
                      rules: [_vm.required],
                    },
                    model: {
                      value: _vm.User.Neighborhood,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "Neighborhood", $$v)
                      },
                      expression: "User.Neighborhood",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            {
              class: _vm.isMobile() ? "mx-0" : "mx-10",
              staticStyle: { "text-align": "left" },
            },
            [
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "5", lg: "5", md: "5", sm: "5" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("address")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      disabled: _vm.disabled_field,
                      loading: _vm.fields_loading,
                      rules: [_vm.required],
                    },
                    model: {
                      value: _vm.User.Address,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "Address", $$v)
                      },
                      expression: "User.Address",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "6", xl: "3", lg: "3", md: "3", sm: "3" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("number")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field mt-2",
                    attrs: {
                      color: "primary",
                      disabled: _vm.noNumber || _vm.disabled_field,
                      outlined: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.User.AddressNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "AddressNumber", $$v)
                      },
                      expression: "User.AddressNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "px-0",
                  attrs: { cols: "6", xl: "4", lg: "4", md: "4", sm: "4" },
                },
                [
                  _c(VCheckbox, {
                    staticClass: "text-field",
                    staticStyle: { color: "var(--dark)" },
                    attrs: {
                      label: "Sem número",
                      disabled: _vm.disabled_field,
                    },
                    model: {
                      value: _vm.noNumber,
                      callback: function ($$v) {
                        _vm.noNumber = $$v
                      },
                      expression: "noNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            {
              class: _vm.isMobile() ? "mx-0" : "mx-10",
              staticStyle: { "text-align": "left" },
            },
            [
              _c(
                VCol,
                { staticClass: "py-0", attrs: { cols: "12" } },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("complement")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field mt-2",
                    attrs: {
                      color: "primary",
                      disabled: _vm.disabled_field,
                      outlined: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.User.AddressComplement,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "AddressComplement", $$v)
                      },
                      expression: "User.AddressComplement",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            {
              class: _vm.isMobile() ? "mx-0" : "mx-10",
              staticStyle: { "text-align": "left" },
            },
            [
              _c(
                VCol,
                { staticClass: "py-0", attrs: { cols: "8", md: "4" } },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("home_phone")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field mt-2",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      number: "",
                      dense: "",
                      rules: [_vm.required],
                    },
                    model: {
                      value: _vm.User.HomePhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "HomePhone", $$v)
                      },
                      expression: "User.HomePhone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            { attrs: { justify: "center" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "btn_next ml-1",
                  attrs: {
                    type: "submit",
                    color: "primary",
                    loading: _vm.loading,
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("next"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
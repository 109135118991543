import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.render
        ? _c(
            VForm,
            {
              ref: "form",
              attrs: { "lazy-validation": _vm.lazy },
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.checkForm()
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(VRow, [
                _c(
                  "p",
                  {
                    staticClass: "mx-auto",
                    staticStyle: {
                      "font-size": "1.5rem",
                      color: "var(--dark)",
                    },
                  },
                  [_c("b", [_vm._v(_vm._s(_vm.$t("professional_info")))])]
                ),
              ]),
              _c(
                VRow,
                { class: _vm.gs.isMobile() ? "mx-0" : "mx-10" },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "12", xl: "5", lg: "5", md: "5", sm: "5" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("profession")) + " "),
                      ]),
                      _c("div", { staticClass: "tooltip-container" }, [
                        _c("img", {
                          staticClass: "tooltip-img",
                          attrs: {
                            src: require("../../../../assets/dxa-icon-tooltip_24px.svg"),
                            alt: "tooltip",
                            "data-tooltip": "tooltip-img",
                          },
                          on: { click: _vm.openTooltip },
                        }),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.tooltip_visible,
                                expression: "tooltip_visible",
                              },
                            ],
                            staticClass: "tooltip-box-text",
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "tooltip-text",
                                attrs: { "data-tooltip": "tooltip-text" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("profession_info_txt")) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c(VAutocomplete, {
                        staticClass: "field",
                        attrs: {
                          color: "primary",
                          filter: _vm.filterObject,
                          items: _vm.profession_options,
                          "item-text": _vm.translateProfessionOptions,
                          "item-value": "id",
                          rules: [_vm.required],
                          label: _vm.$t("your_profession"),
                          dense: "",
                          outlined: "",
                          "data-test": "complete_profile:profession",
                          id: "profession_input",
                        },
                        on: { keyup: _vm.getProfession },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "no-data",
                              fn: function () {
                                return [
                                  _c(
                                    VListItem,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.profession_text_follback
                                                ? _vm.$t(
                                                    "write_your_profession"
                                                  )
                                                : _vm.$t("profession_not_found")
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3741272751
                        ),
                        model: {
                          value: _vm.User.ProfessionId,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "ProfessionId", $$v)
                          },
                          expression: "User.ProfessionId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "my-2" },
                [
                  _c(
                    VRow,
                    { class: _vm.gs.isMobile() ? "mx-0" : "mx-10" },
                    [
                      _c(
                        VCol,
                        {
                          staticClass: "py-0",
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            cols: "12",
                            xl: "6",
                            lg: "6",
                            md: "6",
                            sm: "6",
                          },
                        },
                        [
                          _c("p", { staticClass: "ml-2 mb-2" }, [
                            _vm._v(" " + _vm._s(_vm.$t("employer_type")) + " "),
                          ]),
                          _c(VSelect, {
                            staticClass: "field",
                            attrs: {
                              color: "primary",
                              items: _vm.employer_type_options,
                              "item-text": "text",
                              "item-value": "value",
                              rules: [_vm.required],
                              outlined: "",
                              dense: "",
                              "data-test": "complete_profile:employer_type",
                            },
                            on: { change: _vm.check_employer_type },
                            model: {
                              value: _vm.User.EmployerType,
                              callback: function ($$v) {
                                _vm.$set(_vm.User, "EmployerType", $$v)
                              },
                              expression: "User.EmployerType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        {
                          staticClass: "py-0",
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            cols: "12",
                            xl: "6",
                            lg: "6",
                            md: "6",
                            sm: "6",
                            "display:block": "",
                          },
                        },
                        [
                          _c("p", { staticClass: "ml-2 mb-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("professional_occupation")) +
                                " "
                            ),
                          ]),
                          _c(VTextField, {
                            staticClass: "field",
                            attrs: {
                              color: "primary",
                              outlined: "",
                              dense: "",
                              disabled:
                                _vm.User.EmployerType ==
                                _vm.employerTypeEnum.NO_EMPLOYER,
                              rules: [_vm.required],
                              "data-test":
                                "complete_profile:professional_occupation",
                            },
                            model: {
                              value: _vm.User.ProfessionalOccupation,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.User,
                                  "ProfessionalOccupation",
                                  $$v
                                )
                              },
                              expression: "User.ProfessionalOccupation",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "my-2" },
                [
                  _c(
                    VRow,
                    { class: _vm.gs.isMobile() ? "mx-0" : "mx-10" },
                    [
                      _c(
                        VCol,
                        {
                          staticClass: "py-0",
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            cols: "12",
                            xl: "5",
                            lg: "5",
                            md: "5",
                            sm: "5",
                            "display:block": "",
                          },
                        },
                        [
                          _c("p", { staticClass: "ml-2 mb-2" }, [
                            _vm._v(" " + _vm._s(_vm.$t("company_id")) + " "),
                          ]),
                          _vm.User.EmployerType ===
                          _vm.employerTypeEnum.NATIONAL_EMPLOYER
                            ? _c(VTextField, {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: _vm.einMask,
                                    expression: "einMask",
                                  },
                                ],
                                staticClass: "field",
                                attrs: {
                                  color: "primary",
                                  outlined: "",
                                  disabled:
                                    _vm.User.EmployerType ==
                                    _vm.employerTypeEnum.NO_EMPLOYER,
                                  dense: "",
                                  rules: [
                                    _vm.invalidCnpj === true
                                      ? _vm.customCnpjRule()
                                      : function (v) {
                                          return (
                                            (!!v && v.length === 18) ||
                                            _vm.required()
                                          )
                                        },
                                  ],
                                  "data-test": "complete_profile:company_id",
                                },
                                on: {
                                  keyup: _vm.checkCNPJ,
                                  keypress: _vm.handleOnlyNumber,
                                },
                                model: {
                                  value: _vm.User.EmployerNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.User, "EmployerNumber", $$v)
                                  },
                                  expression: "User.EmployerNumber",
                                },
                              })
                            : _c(VTextField, {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: _vm.einMask,
                                    expression: "einMask",
                                  },
                                ],
                                staticClass: "field",
                                attrs: {
                                  color: "primary",
                                  outlined: "",
                                  disabled:
                                    _vm.User.EmployerType ==
                                    _vm.employerTypeEnum.NO_EMPLOYER,
                                  dense: "",
                                  rules: [
                                    function (v) {
                                      return (
                                        (!!v &&
                                          v.length >= 1 &&
                                          v.length <= 20) ||
                                        _vm.required()
                                      )
                                    },
                                  ],
                                  "data-test": "complete_profile:company_id",
                                },
                                on: {
                                  keyup: _vm.checkCNPJ,
                                  keypress: _vm.handleOnlyNumber,
                                },
                                model: {
                                  value: _vm.User.EmployerNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.User, "EmployerNumber", $$v)
                                  },
                                  expression: "User.EmployerNumber",
                                },
                              }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        {
                          staticClass: "py-0",
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            cols: "12",
                            xl: "7",
                            lg: "7",
                            md: "7",
                            sm: "7",
                            "display:block": "",
                          },
                        },
                        [
                          _c("p", { staticClass: "ml-2 mb-2" }, [
                            _vm._v(" " + _vm._s(_vm.$t("employer")) + " "),
                          ]),
                          _c(VTextField, {
                            staticClass: "field",
                            attrs: {
                              color: "primary",
                              outlined: "",
                              dense: "",
                              disabled:
                                _vm.User.EmployerType ==
                                _vm.employerTypeEnum.NO_EMPLOYER,
                              rules: [_vm.required],
                              "data-test": "complete_profile:employer",
                            },
                            model: {
                              value: _vm.User.Employer,
                              callback: function ($$v) {
                                _vm.$set(_vm.User, "Employer", $$v)
                              },
                              expression: "User.Employer",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "my-2" },
                [
                  _c(
                    VRow,
                    { class: _vm.gs.isMobile() ? "mx-0" : "mx-10" },
                    [
                      _c(
                        VCol,
                        {
                          staticClass: "py-0",
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            cols: "12",
                            xl: "5",
                            lg: "5",
                            md: "5",
                            sm: "5",
                            "display:block": "",
                          },
                        },
                        [
                          _c("p", { staticClass: "ml-2 mb-2" }, [
                            _vm._v(" " + _vm._s(_vm.$t("phone")) + " "),
                          ]),
                          _c(VTextField, {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: [_vm.phoneMask],
                                expression: "[phoneMask]",
                              },
                            ],
                            staticClass: "field",
                            attrs: {
                              color: "primary",
                              outlined: "",
                              dense: "",
                              disabled:
                                _vm.User.EmployerType ==
                                _vm.employerTypeEnum.NO_EMPLOYER,
                              rules: [_vm.required],
                              "data-test": "complete_profile:phone",
                            },
                            model: {
                              value: _vm.User.EmployerPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.User, "EmployerPhone", $$v)
                              },
                              expression: "User.EmployerPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        {
                          staticClass: "py-0",
                          staticStyle: { "text-align": "left" },
                          attrs: {
                            cols: "12",
                            xl: "7",
                            lg: "7",
                            md: "7",
                            sm: "7",
                            "display:block": "",
                          },
                        },
                        [
                          _c("p", { staticClass: "ml-2 mb-2" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("monthly_income")) + " "
                            ),
                          ]),
                          _c(VTextField, {
                            directives: [
                              {
                                name: "money",
                                rawName: "v-money",
                                value: _vm.money,
                                expression: "money",
                              },
                            ],
                            staticClass: "field",
                            attrs: {
                              color: "primary",
                              outlined: "",
                              dense: "",
                              disabled:
                                _vm.User.EmployerType ==
                                _vm.employerTypeEnum.NO_EMPLOYER,
                              rules: [_vm.required],
                              "data-test": "complete_profile:monthly_income",
                            },
                            on: { input: _vm.updateMonthlyIncome },
                            model: {
                              value: _vm.localMonthtlyIncome,
                              callback: function ($$v) {
                                _vm.localMonthtlyIncome = $$v
                              },
                              expression: "localMonthtlyIncome",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                {
                  staticClass: "my-4",
                  class: _vm.gs.isMobile() ? "mx-0" : "mx-10",
                },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      staticStyle: { "text-align": "left" },
                      attrs: { cols: "12", md: "12" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("address")) + " "),
                      ]),
                      _c(VTextField, {
                        staticClass: "field",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          dense: "",
                          disabled:
                            _vm.User.EmployerType ==
                            _vm.employerTypeEnum.NO_EMPLOYER,
                          rules: [_vm.required],
                          "data-test": "complete_profile:address_employer",
                        },
                        model: {
                          value: _vm.User.EmployerAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "EmployerAddress", $$v)
                          },
                          expression: "User.EmployerAddress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VRow,
                { attrs: { justify: "center" } },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "btn_back mr-1",
                      attrs: {
                        outlined: "",
                        color: "primary",
                        loading: _vm.loading,
                        "data-test":
                          "complete_profile:btn_back_professional_info",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("back")
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                        _vm._v(_vm._s(_vm.$t("go_back"))),
                      ]),
                    ]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "btn_next ml-1 mr-0",
                      attrs: {
                        type: "submit",
                        color: "primary",
                        disabled: _vm.next_btn_disabled,
                        loading: _vm.loading,
                        "data-test":
                          "complete_profile:btn_next_professional_info",
                      },
                      on: { click: _vm.checkCNPJ },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                        _vm._v(_vm._s(_vm.$t("next"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
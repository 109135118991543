import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(VRow, [
        _c(
          "p",
          {
            staticClass: "mx-auto",
            staticStyle: { "font-size": "1.5rem", color: "var(--dark)" },
          },
          [_c("b", [_vm._v(_vm._s(_vm.$t("equity_holding")))])]
        ),
      ]),
      _c(
        VBtn,
        {
          attrs: {
            color: "primary",
            rounded: "",
            absolute: "",
            fab: "",
            top: "",
            right: "",
          },
          on: {
            click: function ($event) {
              return _vm.open_dialog(null)
            },
          },
        },
        [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
        1
      ),
      _c(VSimpleTable, [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("name"))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("cpf"))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("nationality"))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("capital"))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("is_politically_exposed"))),
            ]),
            _c("th", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("options"))),
            ]),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.User.EquityHoldings, function (eh, index) {
            return _c("tr", { key: index }, [
              _c("td", { staticClass: "text-center" }, [
                _vm._v(_vm._s(eh.Name)),
              ]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(_vm._s(eh.RegistryNumber)),
              ]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(_vm._s(eh.Nationality)),
              ]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(_vm._s(eh.Capital) + "%"),
              ]),
              _c("td", { staticClass: "text-center" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      eh.PoliticallyExposed ? _vm.$t("yes") : _vm.$t("no")
                    ) +
                    " "
                ),
              ]),
              _c(
                "td",
                { staticClass: "text-center" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "px-2 ml-1 secondary",
                      attrs: { "min-width": "0", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.open_dialog(eh)
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { small: "" } }, [
                        _vm._v("mdi-pencil"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "px-2 ml-1",
                      attrs: { color: "red", "min-width": "0", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.open_delete_dialog(eh)
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { small: "" } }, [
                        _vm._v("mdi-trash-can"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]),
      _c(
        VRow,
        { staticClass: "mt-6", attrs: { justify: "center" } },
        [
          _c(
            VBtn,
            {
              staticClass: "mr-1",
              attrs: { outlined: "", color: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.$emit("back")
                },
              },
            },
            [
              _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                _vm._v(_vm._s(_vm.$t("go_back"))),
              ]),
            ]
          ),
          _c(
            VBtn,
            {
              staticClass: "ml-1",
              attrs: { color: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.$emit("next")
                },
              },
            },
            [
              _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                _vm._v(_vm._s(_vm.$t("next"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c("AddEquityHolding", {
                attrs: { Obj: _vm.equityHolding, UserId: _vm.User.Id },
                on: {
                  update: _vm.update,
                  close: function ($event) {
                    _vm.dialog = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.delete_dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.delete_dialog,
                callback: function ($$v) {
                  _vm.delete_dialog = $$v
                },
                expression: "delete_dialog",
              },
            },
            [
              _c("DeleteConfirmationModal", {
                attrs: {
                  obj: _vm.equityHolding,
                  name: _vm.$t("this_equity_holding"),
                },
                on: {
                  close: function ($event) {
                    _vm.delete_dialog = false
                  },
                  delete: _vm.delete_equity_holding,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
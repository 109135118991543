import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(VRow, [
            _c(
              "p",
              {
                staticClass: "mx-auto",
                staticStyle: { "font-size": "1.5rem", color: "var(--dark)" },
              },
              [_c("strong", [_vm._v(_vm._s(_vm.$t("register_info")))])]
            ),
          ]),
          _c("span", [_vm._v(" " + _vm._s(_vm.$t("marital_status")) + " ")]),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c(
                VRow,
                {
                  staticClass: "d-inline-flex justify-center",
                  class: _vm.isMobile() ? "mx-2" : "mx-12",
                },
                [
                  _c(VAutocomplete, {
                    staticClass: "field",
                    attrs: {
                      color: "primary",
                      dense: "",
                      outlined: "",
                      rules: [_vm.autocomplete_required],
                      items: _vm.maritalStatus,
                      "item-text": _vm.translateMaritalStatus,
                      "item-value": "value",
                      "data-test": "complete_profile:marital_status",
                    },
                    model: {
                      value: _vm.User.MaritalStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "MaritalStatus", $$v)
                      },
                      expression: "User.MaritalStatus",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("span", [_vm._v(" " + _vm._s(_vm.$t("sex")) + " ")]),
          _c(
            "div",
            [
              _c(
                VRow,
                {
                  staticClass: "d-inline-flex justify-center my-4",
                  class: _vm.isMobile() ? "mx-2" : "mx-12",
                },
                [
                  _c(VAutocomplete, {
                    staticClass: "field",
                    attrs: {
                      dense: "",
                      color: "primary",
                      outlined: "",
                      rules: [_vm.autocomplete_required],
                      items: _vm.sexType,
                      "item-text": _vm.translateSexType,
                      "item-value": "value",
                      "data-test": "complete_profile:sex",
                    },
                    model: {
                      value: _vm.User.Sex,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "Sex", $$v)
                      },
                      expression: "User.Sex",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            { attrs: { justify: "center" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "btn_back mr-1",
                  attrs: {
                    outlined: "",
                    color: "primary",
                    loading: _vm.loading,
                    "data-test": "complete_profile:btn_back_register_info",
                    disabled: "disabled",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("go_back"))),
                  ]),
                ]
              ),
              _c(
                VBtn,
                {
                  staticClass: "btn_next ml-1",
                  attrs: {
                    type: "submit",
                    color: "primary",
                    loading: _vm.loading,
                    "data-test": "complete_profile:btn_next_register_info",
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("next"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
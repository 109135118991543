import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(VRow, [
            _c(
              "p",
              {
                staticClass: "mx-auto",
                staticStyle: { "font-size": "1.5rem", color: "var(--dark)" },
              },
              [_c("b", [_vm._v(_vm._s(_vm.$t("bank_info")))])]
            ),
          ]),
          _vm.bankload
            ? _c("ManageBankAccountComponent", {
                attrs: { BankAccount: _vm.user_bank_accounts },
                on: { changeForegin: _vm.resetBankData },
              })
            : _vm._e(),
          _c(
            VRow,
            { staticClass: "bank-info-buttons", attrs: { justify: "center" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "btn_back mr-1",
                  attrs: {
                    outlined: "",
                    color: "primary",
                    loading: _vm.loading,
                    "data-test": "complete_profile:btn_back_bank_info",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("go_back"))),
                  ]),
                ]
              ),
              _c(
                VBtn,
                {
                  staticClass: "btn_next mr-1",
                  attrs: {
                    type: "submit",
                    color: "primary",
                    loading: _vm.loading,
                    "data-test": "complete_profile:btn_next_bank_info",
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("next"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
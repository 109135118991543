import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(VRow, [
            _c(
              "p",
              {
                staticClass: "mx-auto",
                staticStyle: { "font-size": "1.5rem", color: "var(--dark)" },
              },
              [_c("b", [_vm._v(_vm._s(_vm.$t("non_resident_investor")))])]
            ),
          ]),
          _c(
            "div",
            { staticClass: "my-2" },
            [
              _c("span", [_vm._v(" " + _vm._s(_vm.$t("rde")) + " ")]),
              _c(
                VRow,
                {
                  staticClass: "my-4",
                  class: _vm.isMobile() ? "mx-2" : "mx-12",
                },
                [
                  _c(VTextField, {
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      rules: [_vm.required],
                    },
                    model: {
                      value: _vm.User.NonResidentInvestor.RDE,
                      callback: function ($$v) {
                        _vm.$set(_vm.User.NonResidentInvestor, "RDE", $$v)
                      },
                      expression: "User.NonResidentInvestor.RDE",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "my-2" },
            [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.$t("country_representative")) + " "),
              ]),
              _c(
                VRow,
                {
                  staticClass: "my-4",
                  class: _vm.isMobile() ? "mx-2" : "mx-12",
                },
                [
                  _c(VTextField, {
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      rules: [_vm.required],
                    },
                    model: {
                      value:
                        _vm.User.NonResidentInvestor.CountryLegalRepresentative,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.User.NonResidentInvestor,
                          "CountryLegalRepresentative",
                          $$v
                        )
                      },
                      expression:
                        "User.NonResidentInvestor.CountryLegalRepresentative",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "my-2" },
            [
              _c("span", [_vm._v(" " + _vm._s(_vm.$t("cpf")) + " ")]),
              _c(
                VRow,
                {
                  staticClass: "my-4",
                  class: _vm.isMobile() ? "mx-2" : "mx-12",
                },
                [
                  _c(VTextField, {
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      rules: [_vm.required],
                    },
                    model: {
                      value:
                        _vm.User.NonResidentInvestor
                          .CountryLegalRepresentativeSocialNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.User.NonResidentInvestor,
                          "CountryLegalRepresentativeSocialNumber",
                          $$v
                        )
                      },
                      expression:
                        "\n            User.NonResidentInvestor.CountryLegalRepresentativeSocialNumber\n          ",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "my-2" },
            [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.$t("tax_representative")) + " "),
              ]),
              _c(
                VRow,
                {
                  staticClass: "my-4",
                  class: _vm.isMobile() ? "mx-2" : "mx-12",
                },
                [
                  _c(VTextField, {
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      rules: [_vm.required],
                    },
                    model: {
                      value: _vm.User.NonResidentInvestor.TaxRepresentative,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.User.NonResidentInvestor,
                          "TaxRepresentative",
                          $$v
                        )
                      },
                      expression: "User.NonResidentInvestor.TaxRepresentative",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "my-2" },
            [
              _c("span", [_vm._v(" " + _vm._s(_vm.$t("cpf")) + " ")]),
              _c(
                VRow,
                {
                  staticClass: "my-4",
                  class: _vm.isMobile() ? "mx-2" : "mx-12",
                },
                [
                  _c(VTextField, {
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      rules: [_vm.required],
                    },
                    model: {
                      value:
                        _vm.User.NonResidentInvestor
                          .TaxRepresentativeSocialNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.User.NonResidentInvestor,
                          "TaxRepresentativeSocialNumber",
                          $$v
                        )
                      },
                      expression:
                        "User.NonResidentInvestor.TaxRepresentativeSocialNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            { attrs: { justify: "center" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "mr-1",
                  attrs: {
                    outlined: "",
                    color: "primary",
                    loading: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("go_back"))),
                  ]),
                ]
              ),
              _c(
                VBtn,
                {
                  staticClass: "ml-1",
                  attrs: {
                    type: "submit",
                    color: "primary",
                    loading: _vm.loading,
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("next"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
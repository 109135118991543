import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VRow,
        { attrs: { justify: "center" } },
        [
          _c(VCheckbox, {
            staticClass: "my-0 py-0",
            attrs: {
              color: "primary",
              rules: [_vm.required],
              readonly: "",
              "data-test": "complete_profile:agreed_declarations",
            },
            on: { click: _vm.open_declarations },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function () {
                  return [
                    _c("a", {
                      staticStyle: { color: "var(--dark)" },
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("agree_final_declarations")),
                      },
                      on: {
                        click: function ($event) {
                          _vm.declarations = true
                        },
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.agreed_declarations,
              callback: function ($$v) {
                _vm.agreed_declarations = $$v
              },
              expression: "agreed_declarations",
            },
          }),
        ],
        1
      ),
      _c(
        VRow,
        { staticClass: "mt-6", attrs: { justify: "center" } },
        [
          _c(
            VBtn,
            {
              staticClass: "btn_back mr-1 final-declarations-button-back",
              attrs: {
                outlined: "",
                color: "primary",
                "data-test": "complete_profile:btn_back_final_declarations",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("back")
                },
              },
            },
            [
              _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                _vm._v(_vm._s(_vm.$t("go_back"))),
              ]),
            ]
          ),
          _c(
            VBtn,
            {
              staticClass: "ml-1",
              attrs: {
                color: "primary",
                disabled: !_vm.agreed_declarations,
                "data-test": "complete_profile:btn_conclude_final_declarations",
              },
              on: { click: _vm.next },
            },
            [
              _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                _vm._v(_vm._s(_vm.$t("conclude"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.declarations,
            callback: function ($$v) {
              _vm.declarations = $$v
            },
            expression: "declarations",
          },
        },
        [
          _c(
            VCard,
            {
              staticClass: "final-declarations-modal-bg",
              attrs: { color: "white" },
            },
            [
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  _c("p", { staticStyle: { color: "var(--dark)" } }, [
                    _c("b", [
                      _vm._v(_vm._s(_vm.$t("final_declarations_header"))),
                    ]),
                  ]),
                  _c("p", { staticStyle: { color: "var(--dark)" } }, [
                    _vm._v("1: " + _vm._s(_vm.$t("final_declarations_1"))),
                  ]),
                  _c("p", { staticStyle: { color: "var(--dark)" } }, [
                    _vm._v("2: " + _vm._s(_vm.$t("final_declarations_2"))),
                  ]),
                  _c("p", { staticStyle: { color: "var(--dark)" } }, [
                    _vm._v("3: " + _vm._s(_vm.$t("final_declarations_3"))),
                  ]),
                  _c(
                    VRow,
                    {
                      staticClass:
                        "final-declarations-modal-button-agree-terms",
                      attrs: { justify: "center" },
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa-btn-primary",
                          attrs: {
                            "data-test":
                              "complete_profile:btn_agree_final_declarations",
                          },
                          on: { click: _vm.agree_declarations },
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("agree_final_declarations")
                              ),
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    VRow,
                    { staticClass: "mt-2", attrs: { justify: "center" } },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa-btn-secondary-danger",
                          attrs: {
                            "data-test":
                              "complete_profile:btn_close_final_declarations",
                          },
                          on: { click: _vm.decline_declarations },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("close")))])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(VRow, [
            _c(
              "p",
              {
                staticClass: "mx-auto",
                staticStyle: { "font-size": "1.5rem", color: "var(--dark)" },
              },
              [_c("b", [_vm._v(_vm._s(_vm.$t("contact_profile")))])]
            ),
          ]),
          _c(
            "div",
            { staticClass: "my-2 mt-4", attrs: { "hide-details": "" } },
            [
              _c(VRow, { class: _vm.isMobile() ? "mx-4" : "mx-14" }, [
                _c("span", { staticClass: "country-label" }, [
                  _vm._v(" " + _vm._s(_vm.$t("country")) + " "),
                ]),
              ]),
              _c(
                VRow,
                { class: _vm.isMobile() ? "mx-2" : "mx-12" },
                [
                  _c(VSelect, {
                    staticClass: "field",
                    attrs: {
                      dense: "",
                      outlined: "",
                      items: _vm.countries,
                      "item-text": "name",
                      "return-object": "",
                      rules: [_vm.required],
                      loading: !_vm.countries || _vm.countries.length === 0,
                      "data-test": "complete_profile:country",
                      color: "primary",
                    },
                    on: { change: _vm.country_code_changed },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function (data) {
                          return [
                            _c("img", {
                              staticStyle: { width: "20px", height: "auto" },
                              attrs: { src: data.item.flag },
                            }),
                            _c(
                              "span",
                              {
                                staticClass: "ml-2",
                                staticStyle: { "text-transform": "none" },
                              },
                              [_vm._v(_vm._s(data.item.name))]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            _c("img", {
                              staticStyle: { width: "20px", height: "auto" },
                              attrs: { src: data.item.flag },
                            }),
                            _c(
                              "span",
                              {
                                staticClass: "ml-2",
                                staticStyle: { "text-transform": "none" },
                              },
                              [_vm._v(_vm._s(data.item.name))]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.countrySelected,
                      callback: function ($$v) {
                        _vm.countrySelected = $$v
                      },
                      expression: "countrySelected",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                VRow,
                { class: _vm.isMobile() ? "mx-0" : "mx-10" },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "py-0 postCode-box",
                      staticStyle: { "text-align": "left" },
                    },
                    [
                      _c(
                        "h4",
                        { staticClass: "mb-2 mr-3 postCode-title" },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("post_code")) + " "),
                          _c(VSpacer),
                        ],
                        1
                      ),
                      _c("p", { staticClass: "manually colorized" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("inform_your_post_code")) + " "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "postCode-link",
                            on: {
                              click: function ($event) {
                                _vm.disabled_field = false
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("enter_manually")))]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                VRow,
                {
                  staticClass: "requiredMessage-margin",
                  class: _vm.isMobile() ? "mx-0" : "mx-10",
                  staticStyle: { "text-align": "left" },
                },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: {
                        xl: "3",
                        lg: "3",
                        md: "3",
                        sm: "3",
                        "display:block": "",
                      },
                    },
                    [
                      _c(VTextField, {
                        staticClass: "field py-0",
                        attrs: {
                          placeholder: "00000-000",
                          color: "primary",
                          outlined: "",
                          dense: "",
                          rules: [_vm.required],
                          "data-test": "complete_profile:post_code",
                        },
                        on: { keyup: _vm.post_code_changed },
                        model: {
                          value: _vm.User.PostCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "PostCode", $$v)
                          },
                          expression: "User.PostCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "py-0 px-0",
                      attrs: { xl: "4", lg: "4", md: "4", sm: "4" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "manually",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "left",
                            "align-items": "left",
                          },
                          attrs: {
                            href: "https://buscacepinter.correios.com.br/app/endereco/index.php",
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("dont_know_post_code")))]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "left" } },
            [
              _c(
                VRow,
                { class: _vm.isMobile() ? "mx-0" : "mx-10" },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: {
                        cols: "8",
                        xl: "5",
                        lg: "5",
                        md: "5",
                        sm: "5",
                        "display:block": "",
                      },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("city")) + " "),
                      ]),
                      _c(VTextField, {
                        staticClass: "field",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          dense: "",
                          loading: _vm.fields_loading,
                          rules: [_vm.required],
                          disabled: _vm.disabled_field,
                          "data-test": "complete_profile:city",
                        },
                        model: {
                          value: _vm.User.City,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "City", $$v)
                          },
                          expression: "User.City",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: { cols: "4", xl: "2", lg: "2", md: "2", sm: "2" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("state")) + " "),
                      ]),
                      _c(VTextField, {
                        staticClass: "field",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          dense: "",
                          loading: _vm.fields_loading,
                          rules: [_vm.required],
                          disabled: _vm.disabled_field,
                          "data-test": "complete_profile:state",
                        },
                        model: {
                          value: _vm.User.State,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "State", $$v)
                          },
                          expression: "User.State",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "py-0 requiredMessage-margin",
                      attrs: { cols: "12", xl: "5", lg: "5", md: "5", sm: "5" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("neighborhood")) + " "),
                      ]),
                      _c(VTextField, {
                        staticClass: "field pa-0 ma-0",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          dense: "",
                          loading: _vm.fields_loading,
                          rules: [_vm.required],
                          disabled: _vm.disabled_field,
                          "data-test": "complete_profile:neighborhood",
                        },
                        model: {
                          value: _vm.User.Neighborhood,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "Neighborhood", $$v)
                          },
                          expression: "User.Neighborhood",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "left" } },
            [
              _c(
                VRow,
                { class: _vm.isMobile() ? "mx-0" : "mx-10" },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", xl: "6", lg: "6", md: "6", sm: "6" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("address")) + " "),
                      ]),
                      _c(VTextField, {
                        staticClass: "field",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          dense: "",
                          loading: _vm.fields_loading,
                          disabled: _vm.disabled_field,
                          rules: [_vm.required],
                          "data-test": "complete_profile:address",
                        },
                        model: {
                          value: _vm.User.Address,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "Address", $$v)
                          },
                          expression: "User.Address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: { cols: "6", xl: "3", lg: "3", md: "3", sm: "3" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("number")) + " "),
                      ]),
                      _c(VTextField, {
                        staticClass: "field mt-2",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          type: "number",
                          dense: "",
                          rules: [_vm.number_required],
                          disabled: _vm.noNumber || _vm.disabled_field,
                          "data-test": "complete_profile:number",
                        },
                        model: {
                          value: _vm.User.AddressNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "AddressNumber", $$v)
                          },
                          expression: "User.AddressNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "px-0",
                      attrs: { cols: "6", xl: "3", lg: "3", md: "3", sm: "3" },
                    },
                    [
                      _c(VCheckbox, {
                        staticClass: "text-field",
                        staticStyle: { color: "var(--dark)" },
                        attrs: {
                          color: "primary",
                          label: _vm.$t("no_number"),
                          disabled: _vm.disabled_field,
                          "data-test": "complete_profile:no_number",
                        },
                        on: { change: _vm.noNumber_change },
                        model: {
                          value: _vm.noNumber,
                          callback: function ($$v) {
                            _vm.noNumber = $$v
                          },
                          expression: "noNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "requiredMessage-margin",
              staticStyle: { "text-align": "left" },
            },
            [
              _c(
                VRow,
                {
                  class: _vm.isMobile() ? "mx-0" : "mx-10",
                  staticStyle: { display: "block" },
                },
                [
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "12", md: "12" } },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("complement")) + " "),
                      ]),
                      _c(VTextField, {
                        staticClass: "field mt-2",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          dense: "",
                          disabled: _vm.disabled_field,
                          "data-test": "complete_profile:complement",
                        },
                        model: {
                          value: _vm.User.AddressComplement,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "AddressComplement", $$v)
                          },
                          expression: "User.AddressComplement",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { staticClass: "py-0", attrs: { cols: "12", md: "12" } },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("home_phone")) + " "),
                      ]),
                      _c(VTextField, {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: [_vm.PhoneMask],
                            expression: "[PhoneMask]",
                          },
                        ],
                        staticClass: "field",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          dense: "",
                          rules: [_vm.required],
                          "data-test": "complete_profile:home_phone",
                        },
                        model: {
                          value: _vm.User.HomePhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "HomePhone", $$v)
                          },
                          expression: "User.HomePhone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            { attrs: { justify: "center" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "btn_back mr-1",
                  attrs: {
                    outlined: "",
                    color: "primary",
                    loading: _vm.loading,
                    "data-test": "complete_profile:btn_back_contacts",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("go_back"))),
                  ]),
                ]
              ),
              _c(
                VBtn,
                {
                  staticClass: "btn_next ml-1 mr-0",
                  attrs: {
                    type: "submit",
                    color: "primary",
                    loading: _vm.loading,
                    "data-test": "complete_profile:btn_next_contacts",
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("next"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(VRow, [
            _c(
              "p",
              {
                staticClass: "mx-auto",
                staticStyle: { "font-size": "1.5rem", color: "var(--dark)" },
              },
              [_c("b", [_vm._v(_vm._s(_vm.$t("statements")))])]
            ),
          ]),
          _c(
            "div",
            { staticClass: "my-2" },
            [
              _c("span", [
                _vm._v(" " + _vm._s(_vm.$t("is_politically_exposed")) + " "),
              ]),
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { staticClass: "d-inline-flex justify-center" },
                    [
                      _c(
                        VRadioGroup,
                        {
                          attrs: {
                            row: "",
                            center: "",
                            "data-test": "complete_profile:politacally_exposed",
                          },
                          model: {
                            value: _vm.User.PoliticallyExposed,
                            callback: function ($$v) {
                              _vm.$set(_vm.User, "PoliticallyExposed", $$v)
                            },
                            expression: "User.PoliticallyExposed",
                          },
                        },
                        [
                          _c(VRadio, {
                            attrs: { value: true },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [_vm._v(_vm._s(_vm.$t("yes")))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c(VRadio, {
                            attrs: { value: false },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [_vm._v(_vm._s(_vm.$t("no")))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "my-2" },
            [
              _c("span", [_vm._v(" " + _vm._s(_vm.$t("is_us_person")) + " ")]),
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { staticClass: "d-inline-flex justify-center" },
                    [
                      _c(
                        VRadioGroup,
                        {
                          attrs: {
                            row: "",
                            center: "",
                            "data-test": "complete_profile:us_person",
                          },
                          model: {
                            value: _vm.User.USPerson,
                            callback: function ($$v) {
                              _vm.$set(_vm.User, "USPerson", $$v)
                            },
                            expression: "User.USPerson",
                          },
                        },
                        [
                          _c(VRadio, {
                            attrs: { value: true },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [_vm._v(_vm._s(_vm.$t("yes")))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                          _c(VRadio, {
                            attrs: { value: false },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [_vm._v(_vm._s(_vm.$t("no")))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            { attrs: { justify: "center" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "btn_back mr-1",
                  attrs: {
                    outlined: "",
                    color: "primary",
                    loading: _vm.loading,
                    "data-test": "complete_profile:btn_back_statements",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("go_back"))),
                  ]),
                ]
              ),
              _c(
                VBtn,
                {
                  staticClass: "btn_next ml-1",
                  attrs: {
                    type: "submit",
                    color: "primary",
                    loading: _vm.loading,
                    "data-test": "complete_profile:btn_next_statements",
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("next"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(VRow, [
            _c(
              "p",
              {
                staticClass: "mx-auto",
                staticStyle: { "font-size": "1.5rem", color: "var(--dark)" },
              },
              [_c("b", [_vm._v(_vm._s(_vm.$t("personal_docs")))])]
            ),
          ]),
          _c(
            VRow,
            {
              class: _vm.isMobile() ? "mx-0" : "mx-10",
              staticStyle: { "text-align": "left" },
            },
            [
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "4", lg: "4", md: "4", sm: "4" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("document_type")) + " "),
                  ]),
                  _c(VAutocomplete, {
                    staticClass: "field",
                    attrs: {
                      items: _vm.docType,
                      "item-text": _vm.translateDocType,
                      "item-value": "value",
                      "no-data-text": _vm.$t("not_found"),
                      rules: [_vm.enum_required],
                      dense: "",
                      outlined: "",
                      "data-test": "complete_profile:document_type",
                      color: "primary",
                    },
                    on: { change: _vm.docTypeSelector },
                    model: {
                      value: _vm.User.DocumentType,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "DocumentType", $$v)
                      },
                      expression: "User.DocumentType",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "4", lg: "4", md: "4", sm: "4" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("expedition_date")) + " "),
                  ]),
                  _c(
                    VMenu,
                    {
                      ref: "menu",
                      attrs: {
                        "close-on-content-click": false,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "auto",
                        "data-test": "complete_profile:expedition_date_menu",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                VTextField,
                                _vm._g(
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: ["##/##/####"],
                                          expression: "['##/##/####']",
                                        },
                                      ],
                                      staticClass: "field",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        color: "primary",
                                        dense: "",
                                        outlined: "",
                                        "prepend-inner-icon": "mdi-calendar",
                                        rules: [_vm.required],
                                        "data-test":
                                          "complete_profile:expedition_date_picker",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          _vm.date = _vm.parseDate(_vm.dateText)
                                        },
                                      },
                                      model: {
                                        value: _vm.dateText,
                                        callback: function ($$v) {
                                          _vm.dateText = $$v
                                        },
                                        expression: "dateText",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.menuDate,
                        callback: function ($$v) {
                          _vm.menuDate = $$v
                        },
                        expression: "menuDate",
                      },
                    },
                    [
                      _c(VDatePicker, {
                        attrs: {
                          color: "primary",
                          rules: [_vm.required],
                          max: new Date().toISOString().substr(0, 10),
                          min: "1850-01-01",
                        },
                        on: { change: _vm.save },
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "4", lg: "4", md: "4", sm: "4" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("document_number")) + " "),
                  ]),
                  _c(VTextField, {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: [_vm.currentMaskDocType],
                        expression: "[currentMaskDocType]",
                      },
                    ],
                    staticClass: "field",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      dense: "",
                      disabled: _vm.disabled,
                      rules: [
                        _vm.isForeigner == false
                          ? function (v) {
                              return this$1.handleCharacteres(v)
                            }
                          : function (v) {
                              return (!!v && v.length > 5) || _vm.required()
                            },
                      ],
                      "data-test": "Alpha:PersonalDocs:TxtDocumentNumber",
                    },
                    model: {
                      value: _vm.User.DocumentNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "DocumentNumber", $$v)
                      },
                      expression: "User.DocumentNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            {
              class: _vm.isMobile() ? "mx-0" : "mx-10",
              staticStyle: { "text-align": "left" },
            },
            [
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "7", xl: "6", lg: "6", md: "6", sm: "6" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("issuing_body")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: {
                      type: "text",
                      color: "primary",
                      rules: [_vm.required],
                      outlined: "",
                      dense: "",
                      "data-test": "complete_profile:issuing_body",
                    },
                    on: {
                      keypress: function ($event) {
                        return _vm.isLetter($event)
                      },
                    },
                    model: {
                      value: _vm.User.Issuer,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "Issuer", $$v)
                      },
                      expression: "User.Issuer",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "5", xl: "6", lg: "6", md: "6", sm: "6" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("state")) + " "),
                  ]),
                  _vm.isForeigner == false
                    ? _c(VAutocomplete, {
                        staticClass: "field",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          "no-data-text": _vm.$t("not_found"),
                          items: _vm.states,
                          rules: [_vm.required],
                          "item-text": "Nome",
                          "item-value": "Sigla",
                          dense: "",
                          "data-test": "complete_profile:state_autocomplete",
                        },
                        model: {
                          value: _vm.User.IssuerState,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "IssuerState", $$v)
                          },
                          expression: "User.IssuerState",
                        },
                      })
                    : _c(VTextField, {
                        staticClass: "field",
                        attrs: {
                          type: "text",
                          color: "primary",
                          rules: [_vm.required],
                          outlined: "",
                          dense: "",
                          "data-test": "complete_profile:state_text",
                        },
                        model: {
                          value: _vm.User.IssuerState,
                          callback: function ($$v) {
                            _vm.$set(_vm.User, "IssuerState", $$v)
                          },
                          expression: "User.IssuerState",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            {
              class: _vm.isMobile() ? "mx-0" : "mx-10",
              staticStyle: { "text-align": "left" },
            },
            [
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "6", lg: "6", md: "6", sm: "6" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("mother_name")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: {
                      color: "primary",
                      outlined: "",
                      type: "text",
                      dense: "",
                      rules: [_vm.required],
                      "data-test": "complete_profile:mother",
                    },
                    on: {
                      keypress: function ($event) {
                        return _vm.isLetter($event)
                      },
                    },
                    model: {
                      value: _vm.User.MotherName,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "MotherName", $$v)
                      },
                      expression: "User.MotherName",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                {
                  staticClass: "py-0",
                  attrs: { cols: "12", xl: "6", lg: "6", md: "6", sm: "6" },
                },
                [
                  _c("p", { staticClass: "ml-2 mb-2" }, [
                    _vm._v(" " + _vm._s(_vm.$t("father_name")) + " "),
                  ]),
                  _c(VTextField, {
                    staticClass: "field",
                    attrs: {
                      type: "text",
                      color: "primary",
                      outlined: "",
                      dense: "",
                      rules: [_vm.required],
                      "data-test": "complete_profile:father",
                    },
                    on: {
                      keypress: function ($event) {
                        return _vm.isLetter($event)
                      },
                    },
                    model: {
                      value: _vm.User.FatherName,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "FatherName", $$v)
                      },
                      expression: "User.FatherName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("span", [
            _vm._v(" " + _vm._s(_vm.$t("legal_representative_question")) + " "),
          ]),
          _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "d-inline-flex justify-center" },
                [
                  _c(
                    VRadioGroup,
                    {
                      attrs: {
                        row: "",
                        center: "",
                        "data-test": "complete_profile:legal_representative",
                      },
                      model: {
                        value: _vm.User.HasLegalRepresentative,
                        callback: function ($$v) {
                          _vm.$set(_vm.User, "HasLegalRepresentative", $$v)
                        },
                        expression: "User.HasLegalRepresentative",
                      },
                    },
                    [
                      _c(VRadio, {
                        attrs: {
                          color: "primary",
                          value: true,
                          "data-test":
                            "Alpha:PersonalDocs:RadioHasLegalRepresentative",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { color: "var(--dark)" } },
                                  [_vm._v(_vm._s(_vm.$t("yes")))]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c(VRadio, {
                        attrs: {
                          color: "primary",
                          value: false,
                          "data-test":
                            "Alpha:PersonalDocs:RadioHasNoLegalRepresentative",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { color: "var(--dark)" } },
                                  [_vm._v(_vm._s(_vm.$t("no")))]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            { attrs: { justify: "center" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "btn_back mr-1",
                  attrs: {
                    outlined: "",
                    color: "primary",
                    loading: _vm.loading,
                    "data-test": "complete_profile:btn_back_personal_docs",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("go_back"))),
                  ]),
                ]
              ),
              _c(
                VBtn,
                {
                  staticClass: "btn_next ml-1",
                  attrs: {
                    type: "submit",
                    color: "primary",
                    loading: _vm.loading,
                    "data-test": "complete_profile:btn_next_personal_docs",
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("next"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(VRow, [
            _c(
              "p",
              {
                staticClass: "mx-auto",
                staticStyle: { "font-size": "1.5rem", color: "var(--dark)" },
              },
              [_c("b", [_vm._v(_vm._s(_vm.$t("legal_representative_header")))])]
            ),
          ]),
          _c(
            "div",
            { staticStyle: { "text-align": "left" } },
            [
              _c(
                VRow,
                { class: _vm.isMobile() ? "mx-0" : "mx-10" },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", xl: "4", lg: "4", md: "4", sm: "4" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("name")) + " "),
                      ]),
                      _c(VTextField, {
                        staticClass: "field",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          dense: "",
                          rules: [_vm.required],
                        },
                        on: {
                          keypress: function ($event) {
                            return _vm.isLetter($event)
                          },
                        },
                        model: {
                          value: _vm.User.LegalRepresentative.Name,
                          callback: function ($$v) {
                            _vm.$set(_vm.User.LegalRepresentative, "Name", $$v)
                          },
                          expression: "User.LegalRepresentative.Name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", xl: "4", lg: "4", md: "4", sm: "4" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("cpf")) + " "),
                      ]),
                      _c(VTextField, {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "###.###.###-##",
                            expression: "'###.###.###-##'",
                          },
                        ],
                        staticClass: "field",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          dense: "",
                          rules: [
                            function (v) {
                              return (!!v && v.length == 14) || _vm.required()
                            },
                          ],
                        },
                        model: {
                          value: _vm.User.LegalRepresentative.SocialNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.User.LegalRepresentative,
                              "SocialNumber",
                              $$v
                            )
                          },
                          expression: "User.LegalRepresentative.SocialNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", xl: "4", lg: "4", md: "4", sm: "4" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("document_type")) + " "),
                      ]),
                      _c(VAutocomplete, {
                        staticClass: "field",
                        attrs: {
                          center: "",
                          dense: "",
                          "no-data-text": _vm.$t("not_found"),
                          outlined: "",
                          items: _vm.docType,
                          rules: [_vm.requiredNotZero],
                          "item-text": _vm.translateDocType,
                          "item-value": "value",
                        },
                        model: {
                          value: _vm.User.LegalRepresentative.LinkType,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.User.LegalRepresentative,
                              "LinkType",
                              $$v
                            )
                          },
                          expression: "User.LegalRepresentative.LinkType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "left" } },
            [
              _c(
                VRow,
                { class: _vm.isMobile() ? "mx-0" : "mx-10" },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: { cols: "6", xl: "4", lg: "4", md: "4", sm: "4" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("nationality")) + " "),
                      ]),
                      _c(VTextField, {
                        staticClass: "field",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          dense: "",
                          rules: [_vm.required],
                        },
                        on: {
                          keypress: function ($event) {
                            return _vm.isLetter($event)
                          },
                        },
                        model: {
                          value: _vm.User.LegalRepresentative.Nationality,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.User.LegalRepresentative,
                              "Nationality",
                              $$v
                            )
                          },
                          expression: "User.LegalRepresentative.Nationality",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: { cols: "6", xl: "4", lg: "4", md: "4", sm: "4" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("marital_status")) + " "),
                      ]),
                      _c(VAutocomplete, {
                        staticClass: "field",
                        attrs: {
                          center: "",
                          outlined: "",
                          "no-data-text": _vm.$t("not_found"),
                          dense: "",
                          rules: [_vm.required],
                          items: _vm.maritalStatus,
                          "item-text": _vm.translateMaritalStatus,
                          "item-value": "value",
                        },
                        model: {
                          value: _vm.User.LegalRepresentative.MaritalStatus,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.User.LegalRepresentative,
                              "MaritalStatus",
                              $$v
                            )
                          },
                          expression: "User.LegalRepresentative.MaritalStatus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", xl: "4", lg: "4", md: "4", sm: "4" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("profession")) + " "),
                      ]),
                      _c(VTextField, {
                        staticClass: "field",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          dense: "",
                          rules: [_vm.required],
                        },
                        on: {
                          keypress: function ($event) {
                            return _vm.isLetter($event)
                          },
                        },
                        model: {
                          value: _vm.User.LegalRepresentative.Profession,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.User.LegalRepresentative,
                              "Profession",
                              $$v
                            )
                          },
                          expression: "User.LegalRepresentative.Profession",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "left" } },
            [
              _c(
                VRow,
                { class: _vm.isMobile() ? "mx-0" : "mx-10" },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: { cols: "8", xl: "4", lg: "4", md: "4", sm: "4" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("document_type")) + " "),
                      ]),
                      _c(VAutocomplete, {
                        staticClass: "field",
                        attrs: {
                          dense: "",
                          outlined: "",
                          "no-data-text": _vm.$t("not_found"),
                          center: "",
                          rules: [_vm.required],
                          items: _vm.personalDocType,
                          "item-text": _vm.translatePersonalDocType,
                          "item-value": "value",
                        },
                        on: { change: _vm.docTypeSelector },
                        model: {
                          value: _vm.User.LegalRepresentative.DocumentType,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.User.LegalRepresentative,
                              "DocumentType",
                              $$v
                            )
                          },
                          expression: "User.LegalRepresentative.DocumentType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "left" } },
            [
              _c(
                VRow,
                { class: _vm.isMobile() ? "mx-0" : "mx-10" },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", xl: "4", lg: "4", md: "4", sm: "4" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("document_number")) + " "),
                      ]),
                      _c(VTextField, {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: [_vm.currentMaskDocType],
                            expression: "[currentMaskDocType]",
                          },
                        ],
                        staticClass: "field",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          disabled: _vm.disabled_field,
                          rules: [
                            function (v) {
                              return (
                                (!!v && v.length >= _vm.numberOfCharacters) ||
                                _vm.required()
                              )
                            },
                          ],
                          dense: "",
                        },
                        model: {
                          value: _vm.User.LegalRepresentative.DocumentNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.User.LegalRepresentative,
                              "DocumentNumber",
                              $$v
                            )
                          },
                          expression: "User.LegalRepresentative.DocumentNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", xl: "5", lg: "5", md: "5", sm: "5" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("issuing_body")) + " "),
                      ]),
                      _c(VTextField, {
                        staticClass: "field",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          disabled: _vm.disabled_field,
                          dense: "",
                          rules: [_vm.required],
                        },
                        on: {
                          keypress: function ($event) {
                            return _vm.isLetter($event)
                          },
                        },
                        model: {
                          value: _vm.User.LegalRepresentative.Issuer,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.User.LegalRepresentative,
                              "Issuer",
                              $$v
                            )
                          },
                          expression: "User.LegalRepresentative.Issuer",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "left" } },
            [
              _c(
                VRow,
                { class: _vm.isMobile() ? "mx-0" : "mx-10" },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: { cols: "6", xl: "4", lg: "4", md: "4", sm: "4" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("state")) + " "),
                      ]),
                      _c(VAutocomplete, {
                        staticClass: "field",
                        attrs: {
                          color: "primary",
                          outlined: "",
                          "no-data-text": _vm.$t("not_found"),
                          disabled: _vm.disabled_field,
                          items: _vm.states,
                          "item-text": "Nome",
                          "item-value": "Sigla",
                          dense: "",
                          rules: [_vm.required],
                        },
                        model: {
                          value: _vm.User.LegalRepresentative.IssuerState,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.User.LegalRepresentative,
                              "IssuerState",
                              $$v
                            )
                          },
                          expression: "User.LegalRepresentative.IssuerState",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "py-0",
                      attrs: { cols: "6", xl: "3", lg: "3", md: "3", sm: "3" },
                    },
                    [
                      _c("p", { staticClass: "ml-2 mb-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("expedition_date")) + " "),
                      ]),
                      _c(
                        VMenu,
                        {
                          ref: "menuDate",
                          attrs: {
                            "close-on-content-click": false,
                            "return-value": _vm.date,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "290px",
                          },
                          on: {
                            "update:returnValue": function ($event) {
                              _vm.date = $event
                            },
                            "update:return-value": function ($event) {
                              _vm.date = $event
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    VTextField,
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "field pt-0 mt-0 mb-1",
                                          attrs: {
                                            label: _vm.$t("date"),
                                            "prepend-inner-icon":
                                              "mdi-calendar",
                                            outlined: "",
                                            disabled: _vm.disabled_field,
                                            dense: "",
                                            rules: [_vm.required],
                                            readonly: "",
                                            color: "primary",
                                          },
                                          model: {
                                            value: _vm.date,
                                            callback: function ($$v) {
                                              _vm.date = $$v
                                            },
                                            expression: "date",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.menuDate,
                            callback: function ($$v) {
                              _vm.menuDate = $$v
                            },
                            expression: "menuDate",
                          },
                        },
                        [
                          _c(VDatePicker, {
                            staticClass: "mx-auto",
                            on: {
                              change: function ($event) {
                                _vm.$refs.menuDate.save(_vm.date)
                                _vm.menuDate = false
                              },
                            },
                            model: {
                              value: _vm.date,
                              callback: function ($$v) {
                                _vm.date = $$v
                              },
                              expression: "date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "left" } },
            [
              _c(
                VRow,
                { class: _vm.isMobile() ? "mx-0" : "mx-10" },
                [
                  _c(VCol, { staticClass: "py-0" }, [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("non_resident_investor_question")) +
                          " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                VRow,
                { class: _vm.isMobile() ? "mx-0" : "mx-10" },
                [
                  _c(
                    VRadioGroup,
                    {
                      attrs: { center: "", row: "" },
                      model: {
                        value: _vm.User.HasNonResidentInvestor,
                        callback: function ($$v) {
                          _vm.$set(_vm.User, "HasNonResidentInvestor", $$v)
                        },
                        expression: "User.HasNonResidentInvestor",
                      },
                    },
                    [
                      _c(
                        VCol,
                        { staticClass: "py-0" },
                        [
                          _c(VRadio, {
                            attrs: { value: false },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [_vm._v(_vm._s(_vm.$t("no")))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { staticClass: "py-0" },
                        [
                          _c(VRadio, {
                            attrs: { value: true },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [_vm._v(_vm._s(_vm.$t("yes")))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            { attrs: { justify: "center" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "btn_back mr-1",
                  attrs: {
                    outlined: "",
                    color: "primary",
                    loading: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("go_back"))),
                  ]),
                ]
              ),
              _c(
                VBtn,
                {
                  staticClass: "btn_next ml-1",
                  attrs: {
                    type: "submit",
                    color: "primary",
                    loading: _vm.loading,
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("next"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(VRow, [
        _c(
          "p",
          {
            staticClass: "mx-auto",
            staticStyle: { "font-size": "1.5rem", color: "var(--dark)" },
          },
          [_c("b", [_vm._v(_vm._s(_vm.$t("stock_position")))])]
        ),
      ]),
      _c(
        VBtn,
        {
          attrs: {
            color: "primary",
            rounded: "",
            absolute: "",
            fab: "",
            top: "",
            right: "",
          },
          on: {
            click: function ($event) {
              return _vm.open_dialog(null)
            },
          },
        },
        [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
        1
      ),
      _c(
        VForm,
        {
          ref: "form",
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            VRow,
            [
              _c(
                VCol,
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(VTextField, {
                    staticClass: "purple-input",
                    attrs: {
                      color: "primary",
                      type: "number",
                      prefix: "R$",
                      rules: [_vm.required],
                      label: _vm.$t("net_worth"),
                    },
                    on: {
                      change: function ($event) {
                        _vm.User.NetWorth = parseFloat(_vm.User.NetWorth)
                      },
                    },
                    model: {
                      value: _vm.User.NetWorth,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "NetWorth", $$v)
                      },
                      expression: "User.NetWorth",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(VTextField, {
                    staticClass: "purple-input",
                    attrs: {
                      color: "primary",
                      type: "number",
                      prefix: "R$",
                      rules: [_vm.required],
                      label: _vm.$t("average_monthly_revenue"),
                    },
                    on: {
                      change: function ($event) {
                        _vm.User.AverageMonthlyRevenue = parseFloat(
                          _vm.User.AverageMonthlyRevenue
                        )
                      },
                    },
                    model: {
                      value: _vm.User.AverageMonthlyRevenue,
                      callback: function ($$v) {
                        _vm.$set(_vm.User, "AverageMonthlyRevenue", $$v)
                      },
                      expression: "User.AverageMonthlyRevenue",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(VSimpleTable, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("name"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("cpf"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("nationality"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("capital"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("options"))),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.User.StockPositions, function (sp, index) {
                return _c("tr", { key: index }, [
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(sp.Name)),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(sp.RegistryNumber)),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(sp.Nationality)),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(sp.Capital) + "%"),
                  ]),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "px-2 ml-1 secondary",
                          attrs: { "min-width": "0", small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.open_dialog(sp)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-pencil"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "px-2 ml-1",
                          attrs: { color: "red", "min-width": "0", small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.open_delete_dialog(sp)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-trash-can"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
          _c(
            VRow,
            { staticClass: "mt-6", attrs: { justify: "center" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "mr-1",
                  attrs: {
                    outlined: "",
                    color: "primary",
                    loading: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("go_back"))),
                  ]),
                ]
              ),
              _c(
                VBtn,
                {
                  staticClass: "ml-1",
                  attrs: {
                    type: "submit",
                    color: "primary",
                    loading: _vm.loading,
                  },
                },
                [
                  _c("span", { staticStyle: { "font-size": "1.3rem" } }, [
                    _vm._v(_vm._s(_vm.$t("next"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c("AddStockPosition", {
                attrs: { Obj: _vm.stockPosition, UserId: _vm.User.Id },
                on: {
                  update: _vm.update,
                  close: function ($event) {
                    _vm.dialog = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.delete_dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.delete_dialog,
                callback: function ($$v) {
                  _vm.delete_dialog = $$v
                },
                expression: "delete_dialog",
              },
            },
            [
              _c("DeleteConfirmationModal", {
                attrs: {
                  obj: _vm.stockPosition,
                  name: _vm.$t("this_stock_position"),
                },
                on: {
                  close: function ($event) {
                    _vm.delete_dialog = false
                  },
                  delete: _vm.delete_stock_position,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }